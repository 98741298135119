import logo from './logo.svg';
import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [showRewardAdButtonHidden, setShowRewardAdButtonHidden] = useState(false);

  const adStatusCallback = (status) => {
    console.log('Ad Status: ' + status);
    if (status === "ad-initready") {
      setShowRewardAdButtonHidden(false);
    }
  };
  var options = {
    zoneId: 4040,
    devId: 5046,
    gameId: 6123,
    dMode: 1, 
    fallback: 1,
    adStatusCb: adStatusCallback
  };

  useEffect(() => {
    console.log(window)

    // window.initApplixirVideoUnit(options);  //initialize the video before loading
    
  });

  



  const invokeAdOnClick = () => {
    //play the video ad
    // window.playApplixirVideoUnit(options);  
    
    // Invoke video ad
    window.invokeApplixirVideoUnit(options);  
    console.log("waiting...")
  };


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />


        <button onClick={invokeAdOnClick} disabled={showRewardAdButtonHidden}>Invoke Ad</button>
        <div id="applixir_vanishing_div" hidden>
          <iframe id="applixir_parent" allow="autoplay"></iframe>
        </div>
      </header>
    </div>
  );
}

export default App;
